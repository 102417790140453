import CancelIcon from 'apps/embedded-cbc/components/icons/CancelIcon';
import CheckCircleIcon from 'apps/embedded-cbc/components/icons/CheckCircleIcon';
import SandTimerIcon from 'apps/embedded-cbc/components/icons/SandTimerIcon';
import UnavailableIcon from 'apps/embedded-cbc/components/icons/UnavailableIcon';
import UTurnIcon from 'apps/embedded-cbc/components/icons/UTurnIcon';

export const getStatusIcon = (value: string) => {
  switch (value) {
    case 'cancelled':
      return <CancelIcon />;
    case 'completed':
      return <CheckCircleIcon size={20} stroke="#008482" />;
    case 'declined':
      return <UnavailableIcon />;
    case 'pending':
      return <SandTimerIcon />;
    case 'returned':
      return <UTurnIcon />;
    default:
      return '';
  }
};
