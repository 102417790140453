import { OnboardingState } from 'apps/embedded-cbc/contexts/auth/store';

interface AccessControl {
  allowList?: OnboardingState[];
  denyList?: OnboardingState[];
  onboardingState?: OnboardingState;
}

/**
 * Custom hook to determine access control based on the onboarding state of a user.
 * It takes an `AccessControl` object as a parameter, which includes the current onboarding state,
 * and optionally, a deny list and an allow list of onboarding states.
 * @param {OnboardingState} onboardingState - The current onboarding state of the user.
 * @param {OnboardingState[]} denyList - Optional list of onboarding states that are denied access
 * @param {OnboardingState[]} allowList - Optional list of onboarding state that are allowed access.
 * @returns {boolean} - Returns `true` if the current onboarding state is in the allow list and
 *                      not in the deny list, indicating that access is granted. Otherwise,
 *                      returns `false`, indicating that access is denied.
 */
const useAccessControl = ({
  allowList,
  denyList,
  onboardingState,
}: AccessControl) => {
  if (!onboardingState) {
    return false;
  }

  // In the event of allowList and denyList having an overlap, let allowList take precedent
  if (!!onboardingState && allowList?.includes(onboardingState)) {
    return true;
  }

  if (!!onboardingState && denyList?.includes(onboardingState)) {
    return false;
  }

  // I think we should explicitly deny
  return false;
};

export default useAccessControl;
