import React, {
  ChangeEvent,
  FocusEventHandler,
  forwardRef,
  useState,
} from 'react';
import { CSSObject } from 'styled-components';

import EyeVisible from 'apps/embedded-cbc/components/icons/EyeVisible';
import EyeInvisible from 'apps/embedded-cbc/components/icons/EyeInvisible';
import { Box } from 'apps/embedded-cbc/components';
import styled from 'apps/embedded-cbc/components/Input/index.styles';

// Function to format the SSN input as "xxx-xx-xxxx"
const formatSSN = (showSSN: boolean, input?: string) => {
  const formatted = input?.replace(/\D/g, '').slice(0, 9);
  const match = formatted?.match(/^(\d{3})(\d{0,2})(\d{0,4})$/);
  if (showSSN && match) {
    return `${match[1]}${match[2] ? '-' + match[2] : ''}${
      match[3] ? '-' + match[3] : ''
    }`;
  }

  return formatted;
};

interface Props {
  isError?: boolean;
  label: string;
  onBlur?: () => void;
  onChange: (ssn: string) => void;
  onFocus: FocusEventHandler<HTMLInputElement>;
  disabled?: boolean;

  name: string;
  sx?: CSSObject;
  value: string;
}

// eslint-disable-next-line react/display-name
export const SsnInput = forwardRef<HTMLInputElement, Props>(
  (
    { disabled, isError, label, onBlur, onChange, name, onFocus, sx, value },
    ref,
  ) => {
    const [showSSN, setShowSSN] = useState(false);

    // Event handler for SSN input change
    const handleSSNChange = (event: ChangeEvent<HTMLInputElement>): void => {
      const { value } = event.target;
      onChange(String(formatSSN(showSSN, value)));
    };

    const formattedValue = formatSSN(showSSN, value);

    return (
      <styled.InputContainer isDisabled={disabled} isError={isError} sx={sx}>
        <styled.Label htmlFor={name}>
          <styled.Text color="secondary">{label}</styled.Text>
        </styled.Label>
        <Box sx={{ display: 'flex' }}>
          <styled.Input
            disabled={disabled}
            id={name}
            maxLength={11}
            name={name}
            onBlur={onBlur}
            onChange={handleSSNChange}
            onFocus={onFocus}
            placeholder="XXX-XX-XXXX"
            ref={ref}
            type={showSSN ? 'text' : 'password'}
            value={formattedValue}
          />
          <Box
            as="span"
            onClick={() => setShowSSN((previous) => !previous)}
            sx={{ marginRight: '8px' }}
          >
            {showSSN ? <EyeVisible /> : <EyeInvisible />}
          </Box>
        </Box>
      </styled.InputContainer>
    );
  },
);
