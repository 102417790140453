import {
  FieldArrayWithId,
  UseFormRegister,
  Controller,
  Control,
  FieldErrors,
} from 'react-hook-form';

import { FormInput } from 'apps/embedded-cbc/components/KycForm';
import { Box, Input, Text } from 'apps/embedded-cbc/components';
import SelectDropdown from 'apps/embedded-cbc/components/Dropdown';
import { stateMappings } from 'apps/embedded-cbc/utils/states';
import { getErrorMessage } from 'apps/embedded-cbc/components/KycForm/utils';

interface Props {
  errors: FieldErrors<FormInput>;
  fields: FieldArrayWithId<FormInput, 'addresses', 'id'>[];
  register: UseFormRegister<FormInput>;
  control: Control<FormInput>;
}

export const MailingAddress = ({
  errors,
  fields,
  register,
  control,
}: Props) => {
  return (
    <div data-testid="mailing-address">
      <Text sx={{ marginTop: '20px' }}>
        <strong>Add Mailing Address</strong>
      </Text>
      {fields.slice(1, 2).map((field, index) => {
        return (
          <div key={field.id}>
            <Box sx={{ flexBasis: '50%', marginTop: '16px' }}>
              <Input
                isError={!!errors.addresses?.[index + 1]?.street}
                label="Street address 1"
                {...register(`addresses.${index + 1}.street`, {
                  maxLength: 40,
                  pattern: /^[\w #',.-]{0,39}$/,
                  required: true,
                })}
              />
              {!!errors.addresses?.[index + 1]?.street && (
                <Text sx={{ margin: '10px 0' }} variant="error">
                  Enter a valid street address.
                </Text>
              )}
            </Box>
            <Box>
              <Input
                isError={!!errors.addresses?.[index + 1]?.street2}
                label="Street address 2 (optional)"
                sx={{ marginTop: '16px' }}
                value={undefined}
                {...register(`addresses.${index + 1}.street2`, {
                  pattern: /^[\w #',.-]{0,40}$/,
                })}
              />
              {!!errors.addresses?.[index + 1]?.street2 && (
                <Text sx={{ margin: '10px 0' }} variant="error">
                  Enter a valid street address 2.
                </Text>
              )}
            </Box>
            <Box sx={{ display: 'flex', gap: '12px', marginTop: '16px' }}>
              <Input
                isError={!!errors.addresses?.[index + 1]?.city}
                label="City"
                sx={{ flexBasis: '45%' }}
                {...register(`addresses.${index + 1}.city`, {
                  maxLength: 40,
                  pattern: /^[A-Za-z][\w ',.-]{2,39}$/,
                  required: true,
                })}
              />
              <Box sx={{ flexBasis: '30%' }}>
                <Controller
                  control={control}
                  name={`addresses.${index + 1}.state`}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <SelectDropdown
                      defaultValue={value && { label: value, value }}
                      isDisabled={
                        register(`addresses.${index + 1}.state`).disabled
                      }
                      isError={!!error}
                      onChange={(option: any) => onChange(option.value)}
                      options={stateMappings}
                      placeholder="State"
                      sx={{ height: '100%' }}
                    />
                  )}
                  rules={{ required: true }}
                />
              </Box>
              <Input
                isError={!!errors.addresses?.[index + 1]?.zip_code}
                label="Zip"
                sx={{ flexBasis: '25%' }}
                {...register(`addresses.${index + 1}.zip_code`, {
                  pattern: /^\d{5}(?:-\d{4})?$/,
                  required: true,
                })}
              />
            </Box>
            {(!!errors.addresses?.[index + 1]?.city ||
              !!errors.addresses?.[index + 1]?.state ||
              !!errors.addresses?.[index + 1]?.zip_code) && (
              <Text sx={{ margin: '10px 0' }} variant="error">
                {getErrorMessage<FormInput>(errors.addresses?.[index])}
              </Text>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default MailingAddress;
