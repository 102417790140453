import LeftIcon from 'apps/embedded-cbc/components/icons/LeftIcon';
import { Box, Text } from 'apps/embedded-cbc/components';
import { useNavWithTokens } from 'apps/embedded-cbc/contexts/Nav';

interface Props {
  backOverride?: Function;
  backUrl?: string;
  labelOverride?: string;
}

// TODO this should be changed to a button for accessibility
export const BackButton = ({ backOverride, backUrl, labelOverride }: Props) => {
  const { history, push, back } = useNavWithTokens();

  return (
    <Box
      onClick={() => {
        if (backOverride) {
          backOverride();
          return;
        }

        if (backUrl) {
          push(backUrl);
          return;
        }

        history.length > 0 ? back() : push('/settings');
      }}
      sx={{
        ':hover': { cursor: 'pointer' },
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <LeftIcon />
      <Text sx={{ marginLeft: '12px' }}>
        {labelOverride || (history.length > 0 ? 'Back' : 'Settings')}
      </Text>
    </Box>
  );
};
