import styled from 'styled-components';

interface Props {
  variant?: 'primary' | 'secondary';
}

export const ActionLink = styled.span<Props>`
  &:hover {
    cursor: pointer;
  }

  color: ${({ theme }) => theme.actionLink.color};
  font-family: roboto;
  font-size: ${({ variant }) => (variant === 'secondary' ? '12px' : '14px')};
  text-decoration: none;
`;
