const XIcon = () => {
  return (
    <svg
      fill="none"
      height="20"
      viewBox="0 0 24 24"
      width="20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Cancel</title>
      <path
        d="M18 6L6 18"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.87"
        strokeWidth="2"
      />
      <path
        d="M6 6L18 18"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeOpacity="0.87"
        strokeWidth="2"
      />
    </svg>
  );
};

export default XIcon;
