import '../reset.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import Script from 'next/script';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import Head from 'next/head';
import jwt_decode from 'jwt-decode';

import { NavProvider } from 'apps/embedded-cbc/contexts/Nav';
import { defaultConfig } from 'apps/embedded-cbc/config/default';
import { AuthProvider } from 'apps/embedded-cbc/contexts/auth';
import { appConfig } from 'apps/embedded-cbc/config';

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: { retry: false },
    queries: { refetchOnWindowFocus: false },
  },
});

if (process.env.NEXT_PUBLIC_API_MOCKING === 'true') {
  // eslint-disable-next-line unicorn/prefer-module
  require('packages/mocks');
}

if (process.env.NEXT_PUBLIC_APP_ENV === 'prod') {
  datadogRum.init({
    allowedTracingUrls: [
      'https://api-sandbox.embedded.bond.tech',
      'https://api.embedded.bond.tech',
    ],
    applicationId: String(process.env.NEXT_PUBLIC_DD_APPLICATION_ID),
    beforeSend: (event) => {
      // Strip view
      event.view.url = event.view.url.replace(
        /access_token=[^&]*/,
        'access_token=REDACTED',
      );
      event.view.url = event.view.url.replace(
        /refresh_token=[^&]*/,
        'refresh_token=REDACTED',
      );
      // Strip referrer
      event.view.referrer = event.view.referrer?.replace(
        /access_token=[^&]*/,
        'access_token=REDACTED',
      );
      event.view.referrer = event.view.referrer?.replace(
        /refresh_token=[^&]*/,
        'refresh_token=REDACTED',
      );
      return true;
    },
    clientToken: String(process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN),
    defaultPrivacyLevel: 'mask-user-input',
    env: process.env.NEXT_PUBLIC_APP_ENV,
    service: process.env.NEXT_PUBLIC_DD_SERVICE_NAME,
    sessionReplaySampleRate: 100,
    sessionSampleRate: 100,
    site: 'datadoghq.com',
    trackLongTasks: true,
    trackResources: true,
    trackUserInteractions: true,
    useCrossSiteSessionCookie: true,
    version: process.env.NEXT_PUBLIC_RELEASE_VERSION,
  });
  datadogRum.startSessionReplayRecording();
}

function App({ Component, pageProps }: AppProps) {
  const { query, isReady } = useRouter();
  const [config, setConfig] = useState(defaultConfig);
  const [hasConfig, setHasConfig] = useState(false);

  useEffect(() => {
    let access_token = query.access_token as string | undefined;

    if (!access_token) {
      try {
        access_token = window.localStorage.getItem('access_token') || undefined;
      } catch {
        // do nothing
      }
    }

    if (isReady && access_token && !hasConfig) {
      const accessTokenDecoded: any = jwt_decode(String(access_token));
      const config =
        appConfig[String(accessTokenDecoded.client_id)] || appConfig.default;

      setConfig(config);
      setHasConfig(true);
    }
  }, [query, hasConfig, isReady]);

  return (
    <>
      {hasConfig && (
        <ThemeProvider theme={config.theme}>
          <AuthProvider config={config}>
            <NavProvider>
              <QueryClientProvider client={queryClient}>
                <Head>
                  <meta
                    content="width=device-width, initial-scale=1, maximum-scale=1"
                    name="viewport"
                  />
                </Head>
                {process.env.NEXT_PUBLIC_APP_ENV === 'prod' && config.gtmId && (
                  <Script id="google-analytics">
                    {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${config.gtmId}');
            `}
                  </Script>
                )}
                <Component {...pageProps} config={config} />
                <ToastContainer
                  autoClose={3000}
                  hideProgressBar
                  position="bottom-center"
                  theme="dark"
                />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </NavProvider>
          </AuthProvider>
        </ThemeProvider>
      )}
    </>
  );
}

export default withLDProvider({
  clientSideID: String(process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID),
  user: {
    key: 'default',
  },
})(App as React.ComponentType);
