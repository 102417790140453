import ReactModal from 'react-modal';
import styled, { useTheme } from 'styled-components';

import { Box } from '../Box';

import XIcon from './icon';

export const CloseIcon = styled.i`
  &:hover {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  },

  borderRadius: 4px;
  display: flex;
  padding: 8px;
  position: absolute;
  right: 12px;
  top: 12px;
`;

export const Modal = ({
  children,
  isOpen,
  onRequestClose,
  style,
}: ReactModal.Props) => {
  const { modal } = useTheme();

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        content: {
          borderRadius: '18px',
          fontFamily: modal.fontFamily,
          height: 'fit-content',
          inset: '17px',
          margin: '0 auto',
          maxHeight: 'calc(100vh - 2*17px)',
          maxWidth: '420px',
          ...style?.content,
        },
        overlay: { backgroundColor: modal.backgroundColor, ...style?.overlay },
      }}
    >
      <CloseIcon
        onClick={(event) => {
          if (onRequestClose) onRequestClose(event);
        }}
      >
        <XIcon />
      </CloseIcon>
      <Box sx={{ marginTop: '16px' }}>{children}</Box>
    </ReactModal>
  );
};
