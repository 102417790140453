import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';

import { Box, Text } from 'apps/embedded-cbc/components';
import ReactModal from 'react-modal';
import LeftIcon from 'apps/embedded-cbc/components/icons/LeftIcon';
import styled from 'styled-components';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import {
  getFilePlugin,
  DownloadMenuItemProps,
} from '@react-pdf-viewer/get-file';
import { CloseIcon } from '../Modal';
import XIcon from '../Modal/icon';
import DownloadIcon from '../icons/DownloadIcon';
import postMessage from 'apps/embedded-cbc/utils/post-message';

export const PdfViewer = ({
  source,
  isBase64,
  downloadName,
  isOpen,
  onClose,
}: {
  source: string;
  downloadName: string;
  isBase64?: boolean;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const zoomPluginInstance = zoomPlugin();
  const pageNavigationPluginInstance = pageNavigationPlugin();
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => downloadName,
  });

  const { ZoomInButton, ZoomOutButton, ZoomPopover } = zoomPluginInstance;
  const { CurrentPageInput, GoToNextPageButton, GoToPreviousPage } =
    pageNavigationPluginInstance;
  const { Download } = getFilePluginInstance;

  const onMobileDownload = () =>
    postMessage('downloadPdf', {
      filename: downloadName,
      isBase64,
      source,
    });

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={{
        content: {
          borderRadius: 5,
          bottom: 0,
          left: 0,
          margin: '0 auto',
          padding: '20px 0 0 0',
          right: 0,
          top: 0,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '0 15px',
        }}
      >
        <Box
          onClick={() => onClose()}
          sx={{
            ':hover': { cursor: 'pointer' },
            alignItems: 'center',
            display: 'flex',
          }}
        >
          <LeftIcon />
          <Text sx={{ marginLeft: '12px' }}>Back</Text>
        </Box>
        <CloseIcon onClick={() => onClose()}>
          <XIcon />
        </CloseIcon>
      </Box>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
        <Box
          sx={{
            height: 'calc(100svh - 42px)',
            padding: '20px 0 0 0',
            width: '100%',
          }}
        >
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.3)',
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: '#eeeeee',
                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'center',
                padding: '4px',
              }}
            >
              <ZoomOutButton />
              <ZoomPopover />
              <ZoomInButton />
              <GoToPreviousPage />
              <CurrentPageInput />
              <GoToNextPageButton />
              <Download>
                {(props: DownloadMenuItemProps) => (
                  <DownloadContainer
                    onClick={() => {
                      props.onClick();
                      onMobileDownload();
                    }}
                  >
                    <DownloadIcon />
                  </DownloadContainer>
                )}
              </Download>
            </Box>
            <Box
              sx={{
                flex: 1,
                overflow: 'hidden',
              }}
            >
              <Viewer
                fileUrl={
                  isBase64 ? `data:application/pdf;base64,${source}` : source
                }
                plugins={[
                  zoomPluginInstance,
                  pageNavigationPluginInstance,
                  getFilePluginInstance,
                ]}
              />
            </Box>
          </Box>
        </Box>
      </Worker>
    </ReactModal>
  );
};

const DownloadContainer = styled.div`
  cursor: pointer;
  padding: 4px;
  margin-left: 4px;

  &:hover {
    background-color: #d6d6d6;
    border-radius: 0.25em;
  }
`;
