import { useWindowSize } from '@react-hook/window-size';

enum BREAKPOINT {
  MOBILE = 420,
}

const useIsMobile = () => {
  const [width] = useWindowSize();

  return { isMobile: width <= BREAKPOINT.MOBILE };
};

export default useIsMobile;
