import LoadingOverlayWrapper from 'react-loading-overlay-ts';
import { useTheme } from 'styled-components';

import Spinner from '../Spinner';
import { Box } from '../Box';

interface Props {
  children: React.ReactNode;
  isLoading: boolean;
  isHideLoadingContent?: boolean;
  text?: string;
}

export const LoadingOverlay = ({
  children,
  isLoading,
  text = 'Just a second...',
  isHideLoadingContent,
}: Props) => {
  const { loadingBackgroundColor } = useTheme();

  return (
    <LoadingOverlayWrapper
      active={isLoading}
      spinner={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            paddingBottom: '8px',
          }}
        >
          <Spinner color="white" />
        </Box>
      }
      styles={{
        content: (base) => ({
          ...base,
          fontFamily: 'Roboto',
          fontSize: '14px',
        }),
        overlay: (base) => ({
          ...base,
          backgroundColor: loadingBackgroundColor,
          position: 'fixed',
        }),
      }}
      text={text}
    >
      {isHideLoadingContent && isLoading ? <></> : children}
    </LoadingOverlayWrapper>
  );
};
