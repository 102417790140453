import {
  ChangeEventHandler,
  FocusEvent,
  FocusEventHandler,
  forwardRef,
  HTMLInputTypeAttribute,
  ReactNode,
  useState,
  WheelEventHandler,
} from 'react';
import { CSSObject } from 'styled-components';

import { Box } from '../Box';

import styled from './index.styles';

export type Props = {
  id?: string;
  isError?: boolean;
  label: string;
  leftIcon?: ReactNode;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onWheel?: WheelEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  step?: HTMLInputElement['step'];
  sx?: CSSObject;
  type?: HTMLInputTypeAttribute;
  value?: string | undefined;
  disabled?: boolean;
};

// eslint-disable-next-line react/display-name
export const Input = forwardRef<HTMLInputElement, Props>(
  (
    {
      id,
      isError,
      label,
      leftIcon,
      name,
      onChange,
      onWheel,
      onFocus,
      sx,
      type,
      value,
      disabled,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    return (
      <styled.InputContainer
        isDisabled={disabled}
        isError={isError}
        isFocused={isFocused}
        sx={sx}
      >
        <styled.Label htmlFor={name}>
          <styled.Text color="secondary">{label}</styled.Text>
        </styled.Label>
        <Box sx={{ display: 'flex' }}>
          {leftIcon && <Box sx={{ margin: '3px 0 0 12px' }}>{leftIcon}</Box>}
          <styled.Input
            disabled={disabled}
            hasLeftIcon={!!leftIcon}
            id={id ?? name}
            name={name}
            onChange={onChange}
            onWheel={onWheel}
            ref={ref}
            type={type}
            value={value}
            {...props}
            // ensure onBlur/onFocus are not overridden by props
            onBlur={() => setIsFocused(false)}
            onFocus={(event: FocusEvent<HTMLInputElement>) => {
              setIsFocused(true);
              if (onFocus) onFocus(event);
            }}
          />
        </Box>
      </styled.InputContainer>
    );
  },
);

export default Input;
