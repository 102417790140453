/* eslint-disable unicorn/filename-case */
const EyeInvisible = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.70703 2.29297L2.29297 3.70703L5.06836 6.48242C4.52925 6.92191 4.04856 7.39001 3.61914 7.85938C1.78914 9.89938 1 12 1 12C1 12 4 20 12 20C13.2 20 14.2895 19.8198 15.2695 19.5098C15.9886 19.29 16.6486 19.0016 17.248 18.6621L20.293 21.707L21.707 20.293L3.70703 2.29297ZM12 4C10.8 4 9.71047 4.18023 8.73047 4.49023L10.3906 6.15039C10.8906 6.05039 11.43 6 12 6C17.28 6 19.9405 10.27 20.8105 12C20.4805 12.66 19.8709 13.7007 18.9609 14.7207L20.3809 16.1406C22.2109 14.1006 23 12 23 12C23 12 20 4 12 4ZM6.48828 7.90234L8.55859 9.97266C8.19945 10.5721 8 11.2609 8 12C8 12.08 7.99977 12.17 8.00977 12.25C8.12977 14.26 9.74 15.8702 11.75 15.9902C11.83 16.0002 11.92 16 12 16C12.7391 16 13.4279 15.8006 14.0273 15.4414L15.7578 17.1719C15.1084 17.4814 14.3885 17.7198 13.6094 17.8496C13.0994 17.9496 12.57 18 12 18C6.72 18 4.05945 13.73 3.18945 12C3.51945 11.34 4.11906 10.2993 5.03906 9.2793C5.44851 8.80993 5.93918 8.34184 6.48828 7.90234ZM12.25 8.00977L15.9902 11.75C15.8702 9.74 14.26 8.12977 12.25 8.00977Z"
      fill="#767676"
    />
  </svg>
);

export default EyeInvisible;
