import { Box, Text } from 'apps/embedded-cbc/components';

import RobotIcon from 'apps/embedded-cbc/components/icons/RobotIcon';

interface Props {
  supportEmail?: string;
}

export const GenericError = ({ supportEmail }: Props) => (
  <Box sx={{ textAlign: 'center' }}>
    <Box>
      <RobotIcon />
    </Box>
    <Text sx={{ marginTop: '80px' }} variant="h3">
      Oh snap :(
    </Text>
    <Text sx={{ marginTop: '20px' }}>
      We’re having some technical hiccups. Please restart or refresh this
      application to try again.
    </Text>
    <Text sx={{ marginTop: '20px' }}>
      If you keep seeing this error, please contact
      <br />
      <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
    </Text>
  </Box>
);
