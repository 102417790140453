/* eslint-disable unicorn/filename-case */
const LeftIcon = () => (
  <svg
    className="feather feather-chevron-left"
    fill="none"
    height="20"
    stroke="#767676"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline points="15 18 9 12 15 6"></polyline>
  </svg>
);

export default LeftIcon;
