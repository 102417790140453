/* eslint-disable unicorn/filename-case */
const CancelIcon = () => (
  <svg
    className="feather feather-x-octagon"
    fill="none"
    height="20"
    stroke="#767676"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Cancelled</title>
    <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
    <line x1="15" x2="9" y1="9" y2="15"></line>
    <line x1="9" x2="15" y1="9" y2="15"></line>
  </svg>
);

export default CancelIcon;
