/* eslint-disable unicorn/filename-case */
const DownloadIcon = () => (
  <svg
    fill="none"
    height="22px"
    viewBox="0 0 22 22"
    width="22px"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 16L12 8"
      stroke="#323232"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
    <path
      d="M9 13L11.913 15.913V15.913C11.961 15.961 12.039 15.961 12.087 15.913V15.913L15 13"
      stroke="#323232"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
    <path
      d="M3 15L3 16L3 19C3 20.1046 3.89543 21 5 21L19 21C20.1046 21 21 20.1046 21 19L21 16L21 15"
      stroke="#323232"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1"
    />
  </svg>
);

export default DownloadIcon;
