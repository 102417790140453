import api from '.';

export interface GetSdaAccountResponse {
  account_id: string;
  account_number: string;
  balance: {
    current_balance: number;
    available_balance: number;
    previous_statement_balance: number;
    locked_balance: number;
    currency: string;
  };
  cards: string[];
  customer_id: string;
  date_updated: Date;
  date_created: Date;
  deposit: any; // Not sure what this is
  description: string;
  program_id: string;
  routing_number: string;
  status: string;
  type: string;
}

export const getSdaAccount = async () => {
  const response = await api.get<GetSdaAccountResponse>('/accounts/sda');

  return response.data;
};
