import { TextVariant } from 'apps/embedded-cbc/components/Text';
import { Text } from 'apps/embedded-cbc/components';

export const MoneyCell = ({
  value = '0',
  variant = 'body',
}: {
  value?: string;
  variant?: TextVariant;
}) => {
  const formatter = new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  });

  const moneyValue = formatter.format(Number.parseFloat(value) ?? 0);

  return <Text variant={variant}>{moneyValue}</Text>;
};
