export const GTM_EVENTS = {
  activate_card_pageview: 'activate_card.pageview',
  activate_card_submitted: 'activate_card.submitted',
  add_money_account_info_close: 'add_money.account_info.close',
  add_money_continue_overview: 'add_money.continue.overview',
  add_money_pageview: 'add_money.pageview',
  add_money_plaid_link_complete: 'add_money.plaid_link_complete',
  add_money_plaid_link_open: 'add_money.plaid_link_open',
  add_money_plaid_view_account_info: 'add_money.view_account_info',
  approval_add_money: 'approval.add_money',
  approval_pageview: 'approval.pageview',
  cancel_card_pageview: 'cancel_card.pageview',
  card_detail_viewed: 'card_detail.viewed',
  contact_us_pageview: 'contact_us.pageview',
  credit_application: 'credit_application.submitted',
  deposit_account_pageview: 'deposit_account.pageview',
  dispute_pageview: 'dispute.pageview',
  financial_info_pageview: 'financial_info.pageview',
  financial_info_submitted: 'financial_info.submitted',
  form_field_birth_date: 'form_field.birth_date.interacted',
  form_field_city: 'form_field.city.interacted',
  form_field_debt: 'form_field.debt.interacted',
  form_field_different_mailing_address:
    'form_field.different_mailing_address.interacted',
  form_field_email: 'form_field.email.interacted',
  form_field_first_name: 'form_field.first_name.interacted',
  form_field_last_name: 'form_field.last_name.interacted',
  form_field_phone: 'form_field.phone.interacted',
  form_field_primary_street: 'form_field.primary_street.interacted',
  form_field_salary: 'form_field.salary.interacted',
  form_field_ssn: 'form_field.ssn.interacted',
  form_field_state: 'form_field.state.interacted',
  form_field_zip: 'form_field.zip.interacted',
  freeze_unfreeze_card_pageview: 'freeze_unfreeze_card.pageview',
  freeze_unfreeze_card_submitted: 'freeze_unfreeze_card.submitted',
  kyc_declined: 'kyc.declined',
  kyc_persona_inquiry: 'kyc.persona_inquiry',
  kyc_under_review: 'kyc.under_review',
  legal_pageview: 'legal.pageview',
  linked_account_pageview: 'linked_account.pageview',
  linked_account_plaid_start: 'linked_account.plaid_start',
  payments_button_clicked: 'payments_button.clicked',
  payments_form_amount_interacted: 'payments_form.amount.interacted',
  payments_form_from_interacted: 'payments_form.from.interacted',
  payments_pageview: 'payments.pageview',
  payments_pay_now_clicked: 'payments_pay_now.clicked',
  personal_info_pageview: 'personal_info.pageview',
  personal_info_submitted: 'personal_info.submitted',
  report_card_damaged: 'report_card_problem.damaged',
  report_card_lost: 'report_card_problem.lost',
  report_card_pageview: 'report_card.pageview',
  review_terms_agreed: 'review_terms.agreed',
  review_terms_pageview: 'review_terms.pageview',
  set_card_pin_pageview: 'set_card_pin.pageview',
  set_card_pin_submitted: 'set_card_pin.submitted',
  settings_pageview: 'settings.pageview',
  settings_personal_info_pageview: 'settings.personal_info.pageview',
  statements_pageview: 'statements.pageview',
  statements_viewed: 'statements.viewed',
  transfer_form_amount_interacted: 'transfer_form.amount.interacted',
  transfer_form_selected_account_interacted:
    'transfer_form.selected_account.interacted',
  transfer_form_submitted: 'transfer_form.submitted',
  transfers_pageview: 'transfers.pageview',
} as const;

type GTM_EVENT_VALUES = (typeof GTM_EVENTS)[keyof typeof GTM_EVENTS];

const logGtmEvent = (event: GTM_EVENT_VALUES, data: any) => {
  if (window.disable_cookies) {
    return;
  }
  const dataLayer = (window.dataLayer = window.dataLayer || []);
  dataLayer.push({
    event,
    ...data,
  });
};

export default logGtmEvent;
