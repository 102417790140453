/* eslint-disable no-async-promise-executor */
import React, { useContext, createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import jwt, { JwtPayload } from 'jsonwebtoken';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import { AxiosError } from 'axios';
import postMessage from 'apps/embedded-cbc/utils/post-message';

import authStore, {
  IAuthStore,
  AuthStore,
} from 'apps/embedded-cbc/contexts/auth/store';
import api from 'apps/embedded-cbc/api';
import { GenericError } from 'apps/embedded-cbc/components';
import { AppConfig } from 'apps/embedded-cbc/config';

export const setLocalStorageTokens = (
  access_token: string,
  refresh_token: string,
) => {
  try {
    window.localStorage.setItem('access_token', access_token);
    window.localStorage.setItem('refresh_token', refresh_token);
  } catch {
    return;
    // do nothing
  }
};

const AuthContext = createContext<IAuthStore>(authStore);

interface Props {
  children: React.ReactNode;
  config?: AppConfig;
  // This prop is used soley for testing. We cache the store for local dev
  // but want to reinitialize upon every new test
  isMock?: boolean;
  mockAuthStore?: IAuthStore;
}

export const AuthProvider = ({
  children,
  config,
  isMock,
  mockAuthStore,
}: Props) => {
  const ldClient = useLDClient();
  const { query, isReady } = useRouter();
  const store = useAuthContext();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const configureApiInterceptors = () => {
    api.interceptors.request.use((config) => {
      let { accessToken } = store;

      if (!accessToken) {
        try {
          accessToken = window.localStorage.getItem('access_token');
        } catch {
          throw new Error('No access token exists');
        }
      }

      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    });
    // NOTE: by default, useQuery will retry a failed request 3 times
    api.interceptors.response.use(null, async (error: AxiosError) => {
      if (error.config?.url?.includes('auth/token/refresh')) {
        setIsTokenExpired(true);
        // Ensure that a failed request for a refresh token hits the catch block and emits an postMessage error
        return Promise.reject(error);
      }

      if (error.response?.status === 403 && error.config) {
        try {
          await refreshTokens();
          return api.request(error.config);
        } catch {
          window.parent.postMessage('invalidToken', '*'); // for backwards compatibility
          postMessage('invalidToken');
          datadogRum.addError(new Error('refreshToken failed'));
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    });
  };

  const refreshTokens = async () => {
    if (store.tokenRefreshPromise) {
      return store.tokenRefreshPromise;
    }

    store.setTokenRefreshPromise(
      new Promise((resolve, reject) => {
        let { refreshToken } = store;

        if (!refreshToken) {
          try {
            refreshToken = window.localStorage.getItem('refresh_token');
          } catch {
            throw new Error('No refresh token exists');
          }
        }
        api
          .post('/auth/token/refresh', {
            refresh_token: refreshToken,
          })
          .then((response) => {
            store.setTokens(
              response.data.access_token,
              response.data.refresh_token,
            );
            setLocalStorageTokens(
              response.data.access_token,
              response.data.refresh_token,
            );

            resolve(response.data);
            store.setTokenRefreshPromise(null);
          })
          .catch((error) => {
            datadogRum.addError(
              new Error('API request to refresh token failed.'),
            );
            reject(error);
            store.setTokenRefreshPromise(null);
          });
      }),
    );

    return store.tokenRefreshPromise;
  };

  useEffect(() => {
    const checkTokens = async () => {
      if (isAuthenticated || !isReady) {
        return;
      }

      setIsLoading(true);

      // When initializing the app try to pull tokens from query pararms and fall back to local storage for oauth redirects
      let access_token, refresh_token;
      try {
        access_token =
          query.access_token || window.localStorage.getItem('access_token');
        refresh_token =
          query.refresh_token || window.localStorage.getItem('refresh_token');
      } catch {
        setIsAuthenticated(false);
        setIsLoading(false);
        return;
      }

      try {
        const decoded = jwt.decode(refresh_token as string);

        if (
          !decoded ||
          ((decoded as JwtPayload).exp ?? 0) * 1000 <= Date.now()
        ) {
          throw new Error('Refresh token expired');
        }

        configureApiInterceptors();

        // save tokens for subsequent requests
        setLocalStorageTokens(access_token as string, refresh_token as string);
        store.setTokens(access_token as string, refresh_token as string);

        await store.setUser(access_token as string);

        setIsAuthenticated(true);
        setIsLoading(false);
      } catch {
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    };

    checkTokens();
  }, [query, isAuthenticated, isReady, store]);

  useEffect(() => {
    if (store.user) {
      ldClient?.identify({
        custom: { client_id: store.user.client_id },
        key: store.user.brand_person_id,
      });
      datadogRum.setUser({
        client_id: store.user.client_id,
        person_id: store.user.brand_person_id,
      });
    }
  }, [store.user, ldClient]);

  if (isLoading || !isReady) {
    return <></>;
  }

  if (isTokenExpired) {
    return (
      <FullPageContainer>
        <GenericError supportEmail={config?.supportEmail} />
      </FullPageContainer>
    );
  }

  if (!isAuthenticated && process.env.APP_ENV !== 'test') {
    return (
      <FullPageContainer>
        <div id="missing">Missing or invalid access token</div>
      </FullPageContainer>
    );
  }

  return (
    <AuthContext.Provider
      value={isMock ? new AuthStore(mockAuthStore) : authStore}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};

const FullPageContainer = styled.div`
  background-color: #f9f9fc;
  height: 100svh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;
