const postMessage = (event: string, payload?: any) => {
  const message = payload ? { event, payload } : { event };

  const stringifiedMessage = JSON.stringify(message);

  window.parent.postMessage(stringifiedMessage, '*');
  // @ts-ignore
  window.ReactNativeWebView?.postMessage(stringifiedMessage);
};

export default postMessage;
