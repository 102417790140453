import { PuffLoader } from 'react-spinners';
import { CSSObject } from 'styled-components';

export type SpinnerProps = {
  color?: string;
  size?: number;
  sx?: CSSObject;
};

export const Spinner = ({
  size = 60,
  sx = {},
  color = 'blue',
}: SpinnerProps) => {
  return (
    <PuffLoader
      color={color}
      cssOverride={sx || { display: 'flex' }}
      loading
      size={size}
      speedMultiplier={1}
    />
  );
};

export default Spinner;
