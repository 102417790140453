import { defaultConfig } from './default';

import { AppConfig } from '.';

export const bondConfig: AppConfig = {
  ...defaultConfig,
  cardReissue: {
    isEnabled: true,
    reissueAmount: 5,
  },
  gtmId: 'GTM-KQP8VN5',
  minimumInitialDepositInDollars: 5,
};
