import { format, utcToZonedTime } from 'date-fns-tz';

export const getZonedTimestamp = (date: string) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return format(utcToZonedTime(date, timeZone), 'MMM dd, yyyy');
};

export const getOneMonthAgo = () => {
  const currentDate = new Date();
  const oneMonthAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() - 1,
  );

  if (currentDate.getDate() < oneMonthAgo.getDate()) {
    // Adjust the date if it exceeds the number of days in the previous month
    oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
  }
  return oneMonthAgo;
};
