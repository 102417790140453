/* eslint-disable unicorn/filename-case */
const UTurnIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 24 24"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Returned</title>
    <path
      d="M21 14C21 10.7034 18.2966 7 15 7L7.5 7L11 4L9 3L3.92186 7.23178C3.44211 7.63157 3.44211 8.36843 3.92187 8.76822L9 13L11 12L7.5 9L15 9C17.4784 9 19 11.5216 19 14C19 16.4784 17.4784 19 15 19L4 19C3.44772 19 3 19.4477 3 20C3 20.5523 3.44771 21 4 21L15 21C18.2966 21 21 17.2966 21 14Z"
      fill="#767676"
      fillOpacity="0.87"
    />
  </svg>
);

export default UTurnIcon;
