/// <reference types="../../types/window.d.ts" />
// Used for next navigation
import styled from 'styled-components';
import { AnchorHTMLAttributes, ReactNode } from 'react';
import NxtLink, { LinkProps } from 'next/link';

interface Props {
  children: ReactNode;
  externalLinkProps?: AnchorHTMLAttributes<any>;
  href: string;
  queryParams?: Record<string, string>;
  // This is to not use next navigation
  isExternal?: boolean;
  linkProps?: LinkProps;
  variant?: 'body' | 'caption';
}

const Link = styled.a<{ variant: 'body' | 'caption' }>`
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  color: ${({ theme }) => theme.actionLink.color};
  font-family: roboto;
  font-size: ${({ variant }) => (variant === 'body' ? '14px' : '12px')};
  text-decoration: none;
`;

const getTokens = () => {
  const urlParameters = new URLSearchParams(window.location.search);
  return {
    access_token: urlParameters.get('access_token'),
    refresh_token: urlParameters.get('refresh_token'),
  };
};

const setDisableCookies = () => {
  const urlParameters = new URLSearchParams(window.location.search);
  if (window.disable_cookies === undefined) {
    // Don't clobber existing values if URL changes.
    const disableCookies = urlParameters.get('disable_cookies');
    const truthyValues = ['t', 'true'];
    window.disable_cookies =
      disableCookies !== null &&
      truthyValues.includes(disableCookies.toLowerCase());
  }
};

export const NextLink = ({
  children,
  externalLinkProps,
  href,
  isExternal,
  linkProps,
  queryParams,
  variant = 'caption',
}: Props) => {
  const { access_token, refresh_token } = getTokens();
  setDisableCookies();
  const parameters = new URLSearchParams(queryParams);

  if (isExternal) {
    return (
      <Link href={href} variant={variant} {...externalLinkProps}>
        {children}
      </Link>
    );
  }

  return (
    <NxtLink
      {...linkProps}
      href={`${href}?access_token=${access_token}&refresh_token=${refresh_token}&${parameters.toString()}`}
      legacyBehavior
    >
      <Link variant={variant}>{children}</Link>
    </NxtLink>
  );
};
