import styled, { CSSObject, DefaultTheme } from 'styled-components';

export type TextVariant =
  | 'body'
  | 'caption'
  | 'error'
  | 'h1'
  | 'h3'
  | 'h4'
  | 'h5';

interface Props {
  sx?: CSSObject;
  variant?: TextVariant;
}

const getStyles = (variant: TextVariant, theme: DefaultTheme): CSSObject => {
  switch (variant) {
    case 'body':
    default:
      return {
        color: theme.text.body.color,
        fontSize: '14px',
        fontWeight: 400,
        letterSpacing: '0.25px',
        lineHeight: '20px',
      };
    case 'caption':
      return {
        color: theme.text.caption.color,
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
      };
    case 'error':
      return {
        color: theme.text.error.color,
        fontSize: '12px',
        fontWeight: 400,
        letterSpacing: '0.4px',
        lineHeight: '20px',
      };
    case 'h1': {
      return {
        fontSize: '34px',
        fontWeight: 700,
        letterSpacing: '0.25px',
        lineHeight: '40px',
      };
    }
    case 'h3':
      return {
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
      };
    case 'h4':
      return {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '20px',
      };
    case 'h5':
      return {
        fontSize: '24px',
        fontWeight: 500,
        lineHeight: '32px',
      };
  }
};

export const Text = styled.p<Props>(({ sx, theme, variant = 'body' }) => {
  return {
    fontFamily: theme.text.fontFamily,
    marginBlockEnd: 0,
    marginBlockStart: 0,
    ...getStyles(variant, theme),
    ...sx,
  };
});
