/* eslint-disable unicorn/filename-case */
const CheckCircleIcon = ({
  size = 16,
  stroke = 'currentColor',
}: {
  size?: number;
  stroke?: string;
}) => (
  <svg
    className="feather feather-check-circle"
    fill="none"
    height={size}
    stroke={stroke}
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Completed</title>
    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
    <polyline points="22 4 12 14.01 9 11.01"></polyline>
  </svg>
);

export default CheckCircleIcon;
