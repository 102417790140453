import { ComponentType, useEffect } from 'react';

import useAccessControl from 'apps/embedded-cbc/hooks/use-access-control';
import { useAuthContext } from 'apps/embedded-cbc/contexts/auth';
import { OnboardingState } from 'apps/embedded-cbc/contexts/auth/store';
import { useNavWithTokens } from 'apps/embedded-cbc/contexts/Nav';
import useInitialPage from 'apps/embedded-cbc/hooks/use-initial-page';

// Utility function to get the display name of a component
const getDisplayName = <P extends object>(
  WrappedComponent: ComponentType<P>,
) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

/**
 * Higher-Order Component (HOC) for access control based on the onboarding state.
 * @param {ComponentType<P>} WrappedComponent - The component to be wrapped.
 * @param {OnboardingState[]} [allowList] - The allowList of onboarding states.
 * @param {OnboardingState[]} [denyList] - The denylist of onboarding states.
 * @returns {React.FC<P>} - The wrapped component with access control.
 */
export const withAccessControl = <P extends object>(
  WrappedComponent: ComponentType<P>,
  allowList?: OnboardingState[],
  denyList?: OnboardingState[],
) => {
  const WithAccessControl: React.FC<P> = (props) => {
    const { user } = useAuthContext();
    const { push } = useNavWithTokens();
    const { isLoading, pathName } = useInitialPage();
    const hasAccess = useAccessControl({
      allowList,
      denyList,
      onboardingState: user?.onboarding_state,
    });

    useEffect(() => {
      if (!isLoading && !!pathName && !hasAccess) {
        push(pathName);
      }
    }, [hasAccess, isLoading, pathName, push]);

    if (process.env.NODE_ENV === 'test') {
      return <WrappedComponent {...props} />;
    }

    // If does not have access show empty until we redirect in the hook above
    if (!hasAccess) {
      return <></>;
    }

    // Render the wrapped component if access allowed
    return <WrappedComponent {...props} />;
  };

  WithAccessControl.displayName = `withAccessControl(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithAccessControl;
};
