import pick from 'lodash/pick';
import {
  FieldError,
  FieldErrors,
  FieldErrorsImpl,
  FieldValues,
  Merge,
} from 'react-hook-form';

export const getErrorMessage = <T extends FieldValues>(
  errors: FieldErrors<T> | Merge<FieldError, FieldErrorsImpl<T>> | undefined,
) => {
  const filteredErrors = pick(errors, ['city', 'state', 'zip_code']);
  const keys = Object.keys(filteredErrors);

  if (keys.length === 0) {
    return undefined;
  }

  const errorMessageBase =
    keys.length === 1
      ? `Enter a valid ${keys[0]}`.replaceAll('_', ' ')
      : `Enter a valid ${keys.slice(0, -1).join(', ')} and ${
          keys.slice(-1)[0]
        }`.replaceAll('_', ' ');

  return `${errorMessageBase}.`.replaceAll(',', '');
};
