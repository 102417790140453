import { ActionLink, NextLink, PdfViewer } from 'apps/embedded-cbc/components';
import { useState } from 'react';

export const LegalDisplay = ({
  link,
  isPdf,
  label,
}: {
  link: string;
  isPdf?: boolean;
  label: string;
}) => {
  const [pdf, setPdf] = useState<string | null>(null);
  return (
    <>
      {isPdf ? (
        <ActionLink onClick={() => setPdf(link)} variant="secondary">
          {label}
        </ActionLink>
      ) : (
        <NextLink href="/webview" queryParams={{ url: link }}>
          {label}
        </NextLink>
      )}
      <PdfViewer
        downloadName={label}
        isOpen={!!pdf}
        onClose={() => setPdf(null)}
        source={String(pdf)}
      />
    </>
  );
};
