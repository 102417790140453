import { AppConfig } from '.';

export const defaultConfig: AppConfig = {
  brandName: 'Bond',
  cardName: 'Credit Builder Card',
  cardReissue: {
    isEnabled: false,
    reissueAmount: 0,
  },
  images: {
    logoDimensions: {
      height: 20,
      width: 85.7,
    },
  },
  legalese: {
    brandPrivacy: {
      link: 'https://www.getevolved.com',
      text: 'Privacy Policy',
    },
    brandTerms: {
      link: 'https://www.getevolved.com',
      text: 'Terms of Service',
    },
    client: [
      {
        link: 'https://www.getevolved.com',
        text: 'Rewards Program Terms & Conditions',
      },
      {
        link: 'https://www.getevolved.com',
        text: 'Privacy Policy',
      },
    ],
    evolve: {
      bank: {
        link: 'https://www.getevolved.com',
        text: 'Secured Account and Charge Card Account Disclosures and Agreement',
      },
      communications: {
        link: 'https://www.getevolved.com',
        text: 'Electronic Communication Consent',
      },
      privacy: {
        link: 'https://www.getevolved.com',
        text: 'Privacy Policy',
      },
    },
    rewards: {
      link: 'https://www.getevolved.com',
      name: 'Bond',
      text: 'Rewards Program Terms & Conditions',
    },
  },
  maxDepositTransferAmount: 50_000,
  maxWithdrawalTransferAmount: 500,
  publicPath: '/bond',
  sdaName: 'Bond Security Deposit Account',
  supportEmail: 'support@yourbrand.com',
  supportPhone: '1-917-666-6666',
  theme: {
    actionLink: {
      color: '#2b83ea',
    },
    button: {
      borderRadius: '26px',
      cancelBackgroundColor: 'transparent',
      desktopPadding: '12px 24px',
      lineHeight: '22px',
      mobilePadding: '12px 20px',
      primaryBackgroundColor: '#122d58',
      primaryFontColor: '#FFFFFF',
      primaryFontSize: '16px',
      secondaryBackgroundColor: '#F0F0F3',
      secondaryFontColor: 'black',
      secondaryFontSize: '14px',
    },
    cardDetail: {
      fontColor: 'white',
    },
    horizontalLine: {
      borderColor: '#EAECEF',
    },
    input: {
      backgroundColor: '#FFFFFF',
      color: '#000000AD',
      container: {
        backgroundColor: '#FFFFFF',
        borderDefault: '#E5E8EA',
        borderError: '#DB0010',
        borderFocused: '#122E58',
        borderRadius: '6px',
        fontFamily: 'Roboto',
        padding: '8px 0 0',
      },
      fontSize: '16px',
      label: {
        color: '#767676',
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
    loadingBackgroundColor: '#767676',
    modal: {
      backgroundColor: '#000000D1',
      fontFamily: 'Roboto',
    },
    optInButton: {
      backgroundColorNoValue: '#122d58',
      backgroundColorValue: '#6F7F9A',
    },
    selectDropdown: {
      errorBorder: '#DB0010',
      primaryBorder: '#E5E8EA',
    },
    text: {
      body: {
        color: '#000000DE',
      },
      caption: {
        color: '#767676',
      },
      error: {
        color: '#DB0010',
      },
      fontFamily: 'Roboto',
    },
  },
};
