import { useEffect, useState } from 'react';

import { Box, Text } from 'apps/embedded-cbc/components';

interface Props {
  valueToCopy: string;
}

export const CopyButton = ({ valueToCopy }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => setIsCopied(false), 2000);
    }
  }, [isCopied]);

  return (
    <Box
      onClick={() => {
        navigator.clipboard.writeText(valueToCopy);
        setIsCopied(true);
      }}
      sx={{
        ':hover': { cursor: isCopied ? undefined : 'pointer' },

        display: 'inline-block',
      }}
    >
      <Text sx={{ textDecoration: isCopied ? undefined : 'underline' }}>
        {isCopied ? 'Copied!' : 'Copy'}
      </Text>
    </Box>
  );
};
