import { defaultConfig } from './default';

import { AppConfig } from '.';

export const collegeAveConfig: AppConfig = {
  ...defaultConfig,

  brandName: 'College Ave',
  cardName: 'Ambition Card',
  cardReissue: {
    isEnabled: true,
    reissueAmount: 5,
  },
  gtmId: 'GTM-K6968Z',
  images: {
    logoDimensions: {
      height: 23,
      width: 100,
    },
  },
  legalese: {
    ...defaultConfig.legalese,
    brandPrivacy: {
      isPdf: true,
      link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/casl/Ambition-Card-Privacy-Policy.pdf',
      text: 'Privacy Policy',
    },
    brandTerms: {
      isPdf: true,
      link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/casl/Ambition-Card-Terms-of-Use.pdf',
      text: 'Terms of Use',
    },
    client: [
      {
        isPdf: true,
        link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/casl/Ambition-Card-Rewards-Terms-and-Conditions-v2.pdf',
        text: 'Rewards Program Terms & Conditions',
      },
      {
        isPdf: true,
        link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/casl/Ambition-Card-Privacy-Policy.pdf',
        text: 'Privacy Policy',
      },
    ],
    evolve: {
      bank: {
        isPdf: true,
        link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/casl/Evolve-Secured-Account-Charge-Card-Account-Disclosures-Agreement.pdf',
        text: 'Secured Account and Charge Card Account Disclosures and Agreement',
      },
      communications: {
        isPdf: true,
        link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/casl/Evolve-Electronic-Communication-Consent.docx.pdf',
        text: 'Electronic Communication Consent',
      },
      privacy: {
        link: 'https://www.getevolved.com/privacy-policy/',
        text: 'Privacy Policy',
      },
    },
    rewards: {
      isPdf: true,
      link: 'https://embedded-cbc-dev.s3.us-east-2.amazonaws.com/casl/Ambition-Card-Rewards-Terms-and-Conditions-v2.pdf',
      name: 'Ambition Mastercard',
      text: 'Rewards Program Terms & Conditions',
    },
  },
  minimumInitialDepositInDollars: 50,
  publicPath: '/college-ave',
  sdaName: 'Ambition Security Deposit Account',
  supportEmail: 'support@AmbitionCreditCard.com',
  supportPhone: '844-657-0445',
  theme: {
    ...defaultConfig.theme,
    button: {
      ...defaultConfig.theme.button,
      primaryBackgroundColor: '#99CC00',
      primaryFontColor: '#002769',
    },
    optInButton: {
      ...defaultConfig.theme.optInButton,
      backgroundColorNoValue: '#99CC00',
    },
  },
};
