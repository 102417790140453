import Select, { StylesConfig, Props, components } from 'react-select';
import { CSSProperties, useTheme } from 'styled-components';

interface SelectProps extends Partial<Props> {
  isError?: boolean;
  sx?: CSSProperties;
  optionComponent?: any;
  singleValueComponent?: any;
}

export interface OptionType {
  value: string;
  label: string;
}

const SelectDropdown = ({ isDisabled, ...props }: SelectProps) => {
  const { selectDropdown } = useTheme();

  const customStyles: StylesConfig = {
    container: (provided) => ({
      ...provided,
      fontFamily: 'Roboto',
      fontSize: '14px',
      ...props.sx,
    }),
    control: (provided) => ({
      ...provided,
      ...(isDisabled ? { opacity: 0.5, pointerEvents: 'none' } : {}),
      '&:active': { outline: 'none' },
      '&:focus': { outline: 'none' },
      '&:hover': { outline: 'none' },
      border: `1px solid ${
        props.isError
          ? selectDropdown.errorBorder
          : selectDropdown.primaryBorder
      }`,
      boxShadow: 'none',
      height: props.sx?.height || '58px',
      padding: '4px',
    }),
    menu: (provided) => ({
      ...provided,
      top: '45px',
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: '150px',
    }),
    option: (provided) => ({
      ...provided,
      padding: '8px 10px',
    }),
  };

  return (
    <Select
      {...props}
      components={{
        IndicatorSeparator: () => null,
        Option: props.optionComponent || components.Option,
        SingleValue: props.singleValueComponent || components.SingleValue,
      }}
      styles={customStyles}
    />
  );
};

export default SelectDropdown;
