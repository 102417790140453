import {
  Box,
  HorizontalLine,
  MoneyCell,
  Text,
} from 'apps/embedded-cbc/components';
import { getStatusIcon } from 'apps/embedded-cbc/utils/get-status-icon';
import { Transaction } from 'apps/embedded-cbc/types/transaction';
import { getZonedTimestamp } from 'apps/embedded-cbc/utils/date-time-utils';

export const getTransactionName = (transaction: Transaction | null) => {
  // This is for the new ledger release on 7/1/2024
  if (transaction?.details?.description?.toLocaleLowerCase() === 'autopay') {
    return 'Autopay';
  }

  if (
    transaction?.details?.description?.toLocaleLowerCase() === 'repayment' &&
    transaction?.details?.is_card_payment
  ) {
    return 'Repayment';
  }

  if (
    transaction?.details?.description?.toLocaleLowerCase() === 'autodeposit' &&
    transaction?.details?.is_card_payment
  ) {
    return 'Autodeposit';
  }

  if (transaction?.transaction_type === 'Fee') {
    return (
      (transaction.details?.fee_type
        ? transaction.details?.fee_type + ' '
        : '') + 'Fee'
    );
  }

  if (transaction?.transaction_type?.includes('ACH')) {
    return transaction.transaction_type || 'ACH Transfer';
  }

  if (transaction?.transaction_type === 'Account to Account') {
    return transaction.description || 'Account to Account Transfer';
  }

  return transaction?.details.merchant_name;
};

interface Props {
  onClick?: Function;
  transaction: Transaction;
  accountId?: string; // optional accountId of the current display context, if requiring directionality (+/-) for ACH and A2A transfers
}

export const displayTxnAmount = (
  transaction: Transaction,
  currentAccountId: string,
) => {
  if (
    transaction.transaction_type === 'ACH Withdrawal' ||
    transaction.transaction_type === 'External ACH Withdrawal' ||
    (transaction.transaction_type === 'Account to Account' &&
      transaction.details.originating_account_id === currentAccountId)
  ) {
    return Number.parseFloat(transaction.amount) < 0
      ? transaction.amount
      : `-${transaction.amount}`;
  }
  return transaction.amount;
};

export const TransactionRow = ({
  onClick,
  transaction,
  accountId = '',
}: Props) => {
  return (
    <>
      <Box
        onClick={() => {
          if (onClick) onClick();
        }}
        sx={{
          ':hover': { cursor: onClick ? 'pointer' : undefined },

          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <div>{getStatusIcon(transaction.state)}</div>
          <Box sx={{ marginLeft: '12px' }}>
            <Text>{getTransactionName(transaction)}</Text>
            <Text variant="caption">
              {getZonedTimestamp(transaction.created_time)}
            </Text>
          </Box>
        </Box>

        <MoneyCell value={displayTxnAmount(transaction, accountId)} />
      </Box>
      <HorizontalLine sx={{ margin: '20px 0' }} />
    </>
  );
};
