import { ComponentPropsWithoutRef } from 'react';
import { ReactNode } from 'react';
import styled, { CSSObject, DefaultTheme } from 'styled-components';

import useIsMobile from 'apps/embedded-cbc/hooks/use-is-mobile';

type ButtonVariant = 'cancel' | 'primary' | 'secondary';

interface StyledButtonProps {
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isMobile?: boolean;
  sx?: CSSObject;
  variant?: ButtonVariant;
}

const getBackgroundColor = (
  variant: ButtonVariant,
  theme: DefaultTheme,
  isDisabled?: boolean,
) => {
  if (isDisabled) return `${theme.button.primaryBackgroundColor}73`;

  switch (variant) {
    case 'primary':
      return theme.button.primaryBackgroundColor;
    case 'secondary':
      return theme.button.secondaryBackgroundColor;
    case 'cancel':
      return theme.button.cancelBackgroundColor;
  }
};

const StyledButton = styled.button<StyledButtonProps>(
  ({ isDisabled, isFullWidth, isMobile, sx, theme, variant = 'primary' }) => ({
    ':hover': {
      background: `linear-gradient(
        ${getBackgroundColor(variant, theme, isDisabled)}DE,
        ${getBackgroundColor(variant, theme, isDisabled)})`,
      boxShadow: '0px 2px 3px 0px #0000004D',
      cursor: 'pointer',
      transition: 'all 0.2s ease-in-out',
    },

    backgroundColor: getBackgroundColor(variant, theme, isDisabled),
    border: 0,
    borderRadius: theme.button.borderRadius,
    color: (() => {
      if (isDisabled) return `${theme.button.primaryFontColor}73`;

      return variant === 'primary'
        ? theme.button.primaryFontColor
        : theme.button.secondaryFontColor;
    })(),
    fontSize:
      variant === 'primary'
        ? theme.button.primaryFontSize
        : theme.button.secondaryFontColor,
    lineHeight: theme.button.lineHeight,
    padding: isMobile
      ? theme.button.mobilePadding
      : theme.button.desktopPadding,
    width: isFullWidth ? '100%' : undefined,
    ...sx,
  }),
);

interface Props
  extends StyledButtonProps,
    Omit<
      ComponentPropsWithoutRef<'button'>,
      keyof StyledButtonProps | 'disabled'
    > {
  children: ReactNode;
}

export const Button = ({ children, ...rest }: Props) => {
  const { isMobile } = useIsMobile();

  return (
    <StyledButton disabled={rest.isDisabled} isMobile={isMobile} {...rest}>
      {children}
    </StyledButton>
  );
};
