import styled from 'styled-components';

export const Checkbox = ({
  isChecked,
  onChange,
}: {
  isChecked: boolean;
  onChange?: (value: boolean) => void;
}) => {
  return (
    <CheckboxContainer>
      <label>
        <HiddenCheckbox
          checked={isChecked}
          onChange={() => {
            if (onChange) onChange(!isChecked);
          }}
          type="checkbox"
        />
        <StyledCheckbox checked={isChecked}>
          <Icon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </Icon>
        </StyledCheckbox>
      </label>
    </CheckboxContainer>
  );
};

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

const StyledCheckbox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 22px;
  height: 22px;
  background: ${({ checked, theme }) =>
    checked ? theme.actionLink.color : '#fff'};
  border-radius: 4px;
  border: 1px solid #767676;
  cursor: pointer;
  transition: all 150ms;

  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;
