import {
  Control,
  Controller,
  FieldArrayWithId,
  FieldErrors,
  UseFormRegister,
} from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { Dispatch, SetStateAction } from 'react';

import SelectDropdown from '../Dropdown';
import MailingAddress from '../MailingAddress';

import {
  ActionLink,
  Box,
  Button,
  Input,
  SsnInput,
  Text,
} from 'apps/embedded-cbc/components';
import useIsMobile from 'apps/embedded-cbc/hooks/use-is-mobile';
import { Customer } from 'apps/embedded-cbc/types/customer';
import {
  emailRegex,
  nameRegex,
} from 'apps/embedded-cbc/constants/regular-expressions';
import { AppConfig } from 'apps/embedded-cbc/config';
import { LegalDisplay } from '../LegalDispay';
import { stateMappings } from 'apps/embedded-cbc/utils/states';
import { getErrorMessage } from './utils';
import logGtmEvent, {
  GTM_EVENTS,
} from 'apps/embedded-cbc/utils/gtm-event-logger';
import { useAuthContext } from 'apps/embedded-cbc/contexts/auth';

export interface Props {
  brandName: string;
  config: AppConfig;
  control: Control<FormInput, any>;
  errors: FieldErrors<FormInput>;
  fields: FieldArrayWithId<FormInput, 'addresses', 'id'>[];
  handleSubmit: any;
  isSubmitDisabled: boolean;
  hasMailingAddress: boolean;
  register: UseFormRegister<FormInput>;
  setHasMailingAddress: Dispatch<SetStateAction<boolean>>;
  isFormDisabled: boolean;
}

export interface FormInput extends Customer {}

export const KycForm = ({
  brandName,
  config,
  control,
  errors,
  fields,
  handleSubmit,
  isSubmitDisabled,
  hasMailingAddress,
  register: _register,
  setHasMailingAddress,
  isFormDisabled,
}: Props) => {
  const { user } = useAuthContext();
  const { isMobile } = useIsMobile();
  const register: Props['register'] = (...arguments_) =>
    _register(arguments_[0], { disabled: isFormDisabled, ...arguments_[1] });

  return (
    <form onSubmit={handleSubmit} style={{ marginTop: '16px' }}>
      <Box
        sx={{
          display: isMobile ? undefined : 'flex',
          flexWrap: isMobile ? 'wrap' : undefined,
          gap: '4%',
        }}
      >
        <Box>
          <Input
            isError={!!errors.first_name}
            label="First name"
            onFocus={() => {
              logGtmEvent(GTM_EVENTS.form_field_first_name, {
                customer_id: user?.customer_id,
              });
            }}
            sx={{
              flexBasis: isMobile ? '48%' : undefined,
              marginTop: '16px',
            }}
            {...register('first_name', {
              maxLength: 50,
              minLength: 1,
              pattern: nameRegex,
              required: true,
            })}
          />
          {errors.first_name?.type === 'required' && (
            <Text sx={{ margin: '10px 0' }} variant="error">
              First name required.
            </Text>
          )}
          {(errors.first_name?.type === 'minLength' ||
            errors.first_name?.type === 'maxLength') && (
            <Text sx={{ margin: '10px 0' }} variant="error">
              Between 1-50 characters.
            </Text>
          )}
          {errors.first_name?.type === 'pattern' && (
            <Text sx={{ margin: '10px 0' }} variant="error">
              Enter a valid first name.
            </Text>
          )}
        </Box>
        <Box>
          <Input
            isError={!!errors.middle_name}
            label="Middle (optional)"
            sx={{
              flexBasis: isMobile ? '48%' : undefined,
              marginTop: '16px',
            }}
            {...register('middle_name', {
              maxLength: 50,
              pattern: nameRegex,
            })}
          />
          {errors.middle_name?.type === 'maxLength' && (
            <Text sx={{ margin: '10px 0' }} variant="error">
              Max 50 characters.
            </Text>
          )}
          {errors.middle_name?.type === 'pattern' && (
            <Text sx={{ margin: '10px 0' }} variant="error">
              Enter a valid middle name.
            </Text>
          )}
        </Box>
        {isMobile && (
          <Box
            sx={{
              flexBasis: '100%',
              height: 0,
            }}
          />
        )}
        <Box>
          <Input
            isError={!!errors.last_name}
            label="Last name"
            onFocus={() => {
              logGtmEvent(GTM_EVENTS.form_field_last_name, {
                customer_id: user?.customer_id,
              });
            }}
            sx={{
              flexBasis: isMobile ? '100%' : undefined,
              marginTop: '16px',
            }}
            {...register('last_name', {
              maxLength: 50,
              minLength: 2,
              pattern: nameRegex,
              required: true,
            })}
          />
          {errors.last_name?.type === 'required' && (
            <Text sx={{ margin: '10px 0' }} variant="error">
              Last name required.
            </Text>
          )}
          {(errors.last_name?.type === 'minLength' ||
            errors.last_name?.type === 'maxLength') && (
            <Text sx={{ margin: '10px 0' }} variant="error">
              Between 2-50 characters.
            </Text>
          )}
          {errors.last_name?.type === 'pattern' && (
            <Text sx={{ margin: '10px 0' }} variant="error">
              Enter a valid last name.
            </Text>
          )}
        </Box>
      </Box>

      <Input
        isError={!!errors.email}
        label="Email"
        onFocus={() => {
          logGtmEvent(GTM_EVENTS.form_field_email, {
            customer_id: user?.customer_id,
          });
        }}
        sx={{ marginTop: '16px' }}
        {...register('email', {
          maxLength: 320,
          minLength: 3,
          pattern: emailRegex,
          required: true,
        })}
      />
      {(errors.email?.type === 'minLength' ||
        errors.email?.type === 'maxLength') && (
        <Text sx={{ margin: '10px 0' }} variant="error">
          Between 3-320 characters.
        </Text>
      )}
      {(errors.email?.type === 'pattern' ||
        errors.email?.type === 'required') && (
        <Text sx={{ margin: '10px 0' }} variant="error">
          Enter a valid email address.
        </Text>
      )}
      <Controller
        control={control}
        name="phone"
        render={({
          field: { name, onBlur, onChange, ref, value },
          fieldState: { error },
        }) => (
          <PatternFormat
            customInput={Input}
            disabled={isFormDisabled}
            format="(###) ### - ####"
            getInputRef={ref}
            isError={!!error}
            label="Mobile phone"
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={() => {
              logGtmEvent(GTM_EVENTS.form_field_phone, {
                customer_id: user?.customer_id,
              });
            }}
            placeholder="(   )     -     "
            sx={{ marginTop: '16px' }}
            value={value}
          />
        )}
        rules={{
          pattern: /\(\d{3}\)\s\d{3}\s-\s\d{4}/,
          required: true,
        }}
      />
      {!!errors.phone && (
        <Text sx={{ margin: '10px 0' }} variant="error">
          Enter a valid phone number.
        </Text>
      )}
      <Controller
        control={control}
        name="dob"
        render={({
          field: { onBlur, onChange, value, name, ref },
          fieldState: { error },
        }) => (
          <PatternFormat
            customInput={Input}
            disabled={isFormDisabled}
            format="##/##/####"
            getInputRef={ref}
            isError={!!error}
            label="Date of birth"
            mask=""
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={() => {
              logGtmEvent(GTM_EVENTS.form_field_birth_date, {
                customer_id: user?.customer_id,
              });
            }}
            placeholder="MM/DD/YYYY"
            sx={{ marginTop: '16px' }}
            value={value}
          />
        )}
        rules={{
          pattern: /(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/([12]\d{3})/,
          required: true,
          validate: {
            withinYearBounds: (data) => {
              const currentDate = new Date();
              const minDate = new Date(currentDate);
              const maxDate = new Date(currentDate);

              minDate.setFullYear(currentDate.getFullYear() - 125);
              maxDate.setFullYear(currentDate.getFullYear() - 13);

              const [month, day, year] = data.split('/');

              const inputDate = new Date(
                Number(year),
                Number(month),
                Number(day),
              );

              if (inputDate >= minDate && inputDate <= maxDate) {
                return true;
              }

              return 'Birthdate must be within 13 - 125 years ago.';
            },
          },
        }}
      />
      {!!errors.dob && errors.dob?.type !== 'withinYearBounds' && (
        <Text sx={{ margin: '10px 0' }} variant="error">
          Enter a valid date of birth (MM/DD/YYYY)
        </Text>
      )}
      {errors.dob?.type === 'withinYearBounds' && (
        <Text sx={{ margin: '10px 0' }} variant="error">
          {errors.dob?.message}
        </Text>
      )}

      <Controller
        control={control}
        name="ssn"
        render={({
          field: { onBlur, onChange, name, ref, value },
          fieldState: { error },
        }) => (
          <SsnInput
            disabled={isFormDisabled}
            isError={!!error}
            label="Social security number (or ITIN)"
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={() => {
              logGtmEvent(GTM_EVENTS.form_field_ssn, {
                customer_id: user?.customer_id,
              });
            }}
            ref={ref}
            sx={{ marginTop: '16px' }}
            value={value}
          />
        )}
        rules={{
          pattern: {
            message: 'Invalid SSN.',
            value: /^\d{3}(-?\d{2})(-?\d{4})$/,
          },
          required: !isFormDisabled,
        }}
      />
      {!!errors.ssn && (
        <Text sx={{ margin: '10px 0' }} variant="error">
          Enter a valid social security number.
        </Text>
      )}
      <div data-testid="primary-address">
        <Text sx={{ marginTop: '28px' }}>
          <strong>Primary address</strong>
        </Text>
        <Text sx={{ marginBottom: '12px' }}>
          U.S. physical addresses only. No PO boxes.
        </Text>
        {fields.slice(0, 1).map((field, index) => {
          return (
            <div key={field.id}>
              <Box sx={{ flexBasis: '50%', marginTop: '16px' }}>
                <Input
                  isError={!!errors.addresses?.[index]?.street}
                  label="Street address 1"
                  onFocus={() => {
                    logGtmEvent(GTM_EVENTS.form_field_primary_street, {
                      customer_id: user?.customer_id,
                    });
                  }}
                  {...register(`addresses.${index}.street`, {
                    maxLength: 40,
                    pattern: /^([\w #',.-]{0,39}|(?!p\.?o\.? box .*))$/,
                    required: true,
                    validate: {
                      isPoBox: (data) => {
                        if (/p\.?o\.? box/i.test(data ?? '')) {
                          return 'A Physical Address may not be a PO Box';
                        }

                        return true;
                      },
                    },
                  })}
                />
                {!!errors.addresses?.[index]?.street &&
                  errors.addresses?.[index]?.street?.type !== 'isPoBox' && (
                    <Text sx={{ margin: '10px 0' }} variant="error">
                      Enter a valid street address.
                    </Text>
                  )}
                {errors.addresses?.[index]?.street?.type === 'isPoBox' && (
                  <Text sx={{ margin: '10px 0' }} variant="error">
                    {errors.addresses?.[index]?.street?.message}
                  </Text>
                )}
              </Box>
              <Box>
                <Input
                  isError={!!errors.addresses?.[index]?.street2}
                  label="Street address 2 (optional)"
                  sx={{ flexBasis: '50%', marginTop: '16px' }}
                  {...register(`addresses.${index}.street2`, {
                    pattern: /^[\w #',.-]{0,40}$/,
                  })}
                />
                {!!errors.addresses?.[index]?.street2 && (
                  <Text sx={{ margin: '10px 0' }} variant="error">
                    Enter a valid street address 2.
                  </Text>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  gap: '12px',
                  marginTop: '16px',
                }}
              >
                <Input
                  isError={!!errors.addresses?.[index]?.city}
                  label="City"
                  onFocus={() => {
                    logGtmEvent(GTM_EVENTS.form_field_city, {
                      customer_id: user?.customer_id,
                    });
                  }}
                  sx={{ flexBasis: '45%' }}
                  {...register(`addresses.${index}.city`, {
                    maxLength: 40,
                    pattern: /^[A-Za-z][\w ',.-]{2,39}$/,
                    required: true,
                  })}
                />
                <Box sx={{ flexBasis: '30%' }}>
                  <Controller
                    control={control}
                    name={`addresses.${index}.state`}
                    render={({
                      field: { value, onChange },
                      fieldState: { error },
                    }) => (
                      <SelectDropdown
                        defaultValue={value && { label: value, value }}
                        isDisabled={isFormDisabled}
                        isError={!!error}
                        onChange={(option: any) => onChange(option.value)}
                        onFocus={() => {
                          logGtmEvent(GTM_EVENTS.form_field_state, {
                            customer_id: user?.customer_id,
                          });
                        }}
                        options={stateMappings}
                        placeholder="State"
                        sx={{ height: '100%' }}
                        value={value && { label: value, value }}
                      />
                    )}
                    rules={{ required: true }}
                  />
                </Box>
                <Input
                  isError={!!errors.addresses?.[index]?.zip_code}
                  label="Zip"
                  onFocus={() => {
                    logGtmEvent(GTM_EVENTS.form_field_zip, {
                      customer_id: user?.customer_id,
                    });
                  }}
                  sx={{ flexBasis: '25%' }}
                  {...register(`addresses.${index}.zip_code`, {
                    pattern: /^\d{5}(?:-\d{4})?$/,
                    required: true,
                  })}
                />
              </Box>
              {(!!errors.addresses?.[index]?.city ||
                !!errors.addresses?.[index]?.state ||
                !!errors.addresses?.[index]?.zip_code) && (
                <Text sx={{ margin: '10px 0' }} variant="error">
                  {getErrorMessage<FormInput>(errors.addresses?.[index])}
                </Text>
              )}
            </div>
          );
        })}
      </div>
      <ActionLink
        onClick={() => {
          setHasMailingAddress(!hasMailingAddress);
          logGtmEvent(GTM_EVENTS.form_field_different_mailing_address, {
            customer_id: user?.customer_id,
          });
        }}
      >
        <Text sx={{ color: '#2b83ea', margin: '28px 0' }}>
          My mailing address is different...
        </Text>
      </ActionLink>
      {hasMailingAddress && (
        <MailingAddress
          control={control}
          errors={errors}
          fields={fields}
          register={register}
        />
      )}
      <Text
        sx={{ marginTop: hasMailingAddress ? '28px' : undefined }}
        variant="caption"
      >
        By pressing “Next”, you verify that the above information is correct and
        you agree to {brandName}’s{' '}
        <LegalDisplay
          isPdf={config.legalese.brandTerms.isPdf}
          label={config.legalese.brandTerms.text}
          link={config.legalese.brandTerms.link}
        />{' '}
        and{' '}
        <LegalDisplay
          isPdf={config.legalese.brandPrivacy.isPdf}
          label={config.legalese.brandPrivacy.text}
          link={config.legalese.brandPrivacy.link}
        />
        .
      </Text>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          isDisabled={isSubmitDisabled}
          sx={{ margin: '24px 0', width: isMobile ? '100%' : '350px' }}
          type="submit"
        >
          Next
        </Button>
      </Box>
    </form>
  );
};
