import { CSSObject, useTheme } from 'styled-components';

import { Box } from 'apps/embedded-cbc/components';

export const HorizontalLine = ({ sx }: { sx?: CSSObject }) => {
  const { horizontalLine } = useTheme();

  return (
    <Box sx={{ border: `0.5px solid ${horizontalLine.borderColor}`, ...sx }} />
  );
};
