export const alphaNumericWithHyphen = /^[\dA-Za-z-]+$/;
export const alphaNumericWithSpace = /^[\d\sA-Za-z]*$/;
export const alphaWithSpaceOrHyphen = /^[\sA-Za-z-]*$/;
export const cardNumberDigits = /^\d{16}$/;
// https://www.abstractapi.com/tools/email-regex-guide
export const emailRegex =
  // eslint-disable-next-line unicorn/better-regex
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const fourDigits = /^\d{4}$/;
export const phoneNumberDashFormat = /^[1-9]\d{2}-\d{3}-\d{4}$/;
export const uuidRegex =
  /^[\dA-Fa-f]{8}(?:\b-[\dA-Fa-f]{4}){3}\b-[\dA-Fa-f]{12}$/;
export const zipCodeFiveOrPlusFour = /^\d{5}(?:-\d{4})?$/;
// eslint-disable-next-line unicorn/better-regex
export const nameRegex = /^[A-Za-z][- '‘’,.A-Za-z]{0,49}$/;
