/* eslint-disable unicorn/filename-case */
const UnavailableIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 24 24"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>Declined</title>
    <path
      d="M12 1C5.93789 1 1 5.9379 1 12C1 18.0621 5.93789 23 12 23C18.0621 23 23 18.0621 23 12C23 5.9379 18.0621 1 12 1ZM12 3C14.0375 3 16.0112 3.84493 17.5 5L5 17.5C3.84493 16.0112 3 14.0375 3 12C3 7.12686 7.12686 3 12 3ZM19 6.5C20.1551 7.98879 21 9.96252 21 12C21 16.8731 16.8731 21 12 21C9.96252 21 7.98879 20.1551 6.5 19L19 6.5Z"
      fill="#DB0010"
    />
  </svg>
);

export default UnavailableIcon;
