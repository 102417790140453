/* eslint-disable unicorn/filename-case */
const SandTimerIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 24 24"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>pending</title>
    <path
      d="M21 22L21 21.6C21 20.8069 20.4731 20 19.68 20L19 20L19 17C19 15.5562 18.2019 13.8006 17 13L15 12L17 11C18.2019 10.1994 19 8.44375 19 7L19 4L19.68 4C20.4731 4 21 3.29312 21 2.5L21 2L3 2L3 2.5C3 3.29312 3.52687 4 4.32 4L5 4L5 7.06502C5 8.50877 5.79813 10.1994 7 11L9 12L7 13C5.79813 13.8006 5 15.5562 5 17L5 20L4.32 20C3.52687 20 3 20.7069 3 21.5L3 22L21 22ZM17.5 20L6.5 20L6.5 16.935C6.5 15.8119 6.80437 14.7675 7.74 14.145L10.5 12.5L10.649 12.3345C10.8201 12.1443 10.8201 11.8557 10.649 11.6655L10.5 11.5L7.74 9.85502C6.80437 9.23064 6.5 8.18814 6.5 7.06502L6.5 4L17.5 4L17.5 7.06502C17.5 8.18814 17.1956 9.23064 16.26 9.85502L13.5 11.5L13.351 11.6655C13.1799 11.8557 13.1799 12.1443 13.351 12.3345L13.5 12.5L16.26 14.145C17.1956 14.7675 17.5 15.8119 17.5 16.935L17.5 20ZM11.6379 10.3356C11.868 10.449 12.131 10.4468 12.3596 10.3303C13.1712 9.91654 15.1435 8.73261 16.0359 6.62806C16.166 6.32115 15.9266 6 15.5932 6L8.40088 6C8.06929 6 7.82988 6.31802 7.95605 6.62467C8.83896 8.77055 10.8214 9.93301 11.6379 10.3356Z"
      fill="#767676"
      fillOpacity="0.6"
    />
  </svg>
);

export default SandTimerIcon;
