import { ReactNode } from 'react';
import styled, { CSSObject } from 'styled-components';

import { Box } from 'apps/embedded-cbc/components';

// Generic page content styling
const InnerContainer = styled.div`
  padding: 20px 16px;
  margin: 0 auto;
  max-width: 600px;
  min-width: 356px; // ensure no x-scroll on 360px screens
`;

interface Props {
  children: ReactNode;
  sx?: CSSObject;
}

export const Container = ({ children, sx }: Props) => {
  return (
    <Box sx={{ minHeight: '100svh', ...sx }}>
      <InnerContainer>{children}</InnerContainer>
    </Box>
  );
};
