import { makeAutoObservable } from 'mobx';
import jwt_decode from 'jwt-decode';

import api from 'apps/embedded-cbc/api';

export type OnboardingState =
  | 'ACCOUNT_LINKED'
  | 'APPLICATION_APPROVED'
  | 'APPLICATION_CREATED'
  | 'APPLICATION_SUBMITTED'
  | 'CARD_ISSUED'
  | 'COMPLETE'
  | 'CUSTOMER_CREATED'
  | 'KYC_DOC_REQUIRED'
  | 'KYC_FAILED'
  | 'KYC_PASSED'
  | 'KYC_SUBMITTED'
  | 'KYC_UNDER_REVIEW'
  | 'WELCOME';

export interface User {
  application_id: string | null;
  brand_person_id: string;
  card_id: string | null;
  client_id: string;
  customer_id: string | null;
  onboarding_state: OnboardingState;
  sda_id: string | null;
  card_account_id: string | null;
}

export interface IAuthStore {
  patchUser: (fields: Partial<User>) => void;
  setUser: (token: string) => Promise<User>;
  setTokens: (accessToken: string, refreshToken: string) => void;
  setTokenRefreshPromise: (promise: Promise<any | null> | null) => void;
  tokenRefreshPromise: Promise<any | null> | null;
  accessToken: string | null;
  refreshToken: string | null;
  user?: User | null;
}

export class AuthStore implements IAuthStore {
  clientId: string = '';
  accessToken: string | null = null;
  refreshToken: string | null = null;
  tokenExp: number = 0;
  userId: string = '';

  user?: User | null = null;

  tokenRefreshPromise: Promise<any | null> | null = null;

  constructor(initialValues?: IAuthStore) {
    this.user = initialValues?.user;

    if (initialValues?.patchUser) {
      this.patchUser = initialValues.patchUser;
    }

    makeAutoObservable(this);
  }

  isTokenExpired = () => {
    return Date.now() >= this.tokenExp * 1000;
  };

  setTokenRefreshPromise = (value: Promise<any | null> | null) => {
    this.tokenRefreshPromise = value;
  };

  setTokens = (accessToken: string, refreshToken: string) => {
    this.accessToken = accessToken;
    this.refreshToken = refreshToken;
  };

  setUser = async (token: string) => {
    const decoded: any = jwt_decode(token);

    this.tokenExp = decoded?.exp;
    this.userId = decoded?.user_id;
    this.clientId = decoded?.client_id;

    try {
      const response = await api.get<User>('/user');
      this.user = response.data;
      return response.data;
    } catch {
      throw new Error('Error getting user');
    }
  };

  patchUser = (fields: Partial<User>) => {
    if (this.user) {
      this.user = { ...this.user, ...fields };
    }
  };
}

const authStore = new AuthStore();

export default authStore;
